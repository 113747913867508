import { serialize } from 'object-to-formdata'

export default class FieldsApiClient {
  constructor (http) {
    this.http = http
  }

  /**
   * Gets a custom field based on an endpoint
   *
   * @returns {Array}
  */
  async getFields (endpoint, params = undefined) {
    const result = await this.http.get(endpoint + '?includes=children', { params })
    return result.data.data
  }

  async getCountries () {
    const result = await this.http.get('/fields/locations/countries')
    return result.data.data
  }

  async getRegions (params) {
    const result = await this.http.get('/fields/locations/regions', {
      params,
    })
    return result.data.data
  }

  async getSubregions () {
    const result = await this.http.get('/fields/locations/sub-regions')
    return result.data.data
  }

  /**
   * Creates a new field at the given endpoint with an optional parent and a title
   *
   * @returns {Array}
  */
  async createField (endpoint, data, hasMedia) {
    const result = await this.http.post(endpoint, hasMedia ? serialize(data) : data, {
      headers: {
        'Content-Type': hasMedia ? 'multipart/form-data' : 'application/json',
      },
    })
    return result.data.data
  }

  /**
   * Updates a field at the given endpoint with an optional parent and a title
   *
   * @returns {Array}
  */
  async updateField (endpoint, id, data, hasMedia) {
    const result = await this.http.patch(`${endpoint}/${id}`, hasMedia ? serialize(data) : data, {
      headers: {
        'Content-Type': hasMedia ? 'multipart/form-data' : 'application/json',
      },
    })
    return result.data.data
  }

  /**
   * Deletes a field at the given endpoint
   *
   * @returns {Array}
  */
  async deleteField (endpoint, id, parentId, value) {
    const result = await this.http.delete(`${endpoint}/${id}`, {
      parent_id: parentId,
      value: value,
    })
    return result.data.data
  }
}
