import { formatDateLong, formatEnum, joinBy, pluralise } from '@ama-selections/ui'
import { getStartTime } from 'data/helpers/survey/tripSchedule'
import { TripScheduleService } from 'data/types/bookings/trip_schedule'
import { AirportTransferDataType } from 'gql/graphql'

const getAirportTransferServiceDetails = (service: TripScheduleService) => {
  const serviceDetails = service.service_details ?? service.group?.service_details

  if (serviceDetails?.includes('$generate_details') && service.survey?.__typename === 'SurveyAirportTransferData') {
    const surveyData = service.survey

    const type = surveyData.type
      ? `${formatEnum(surveyData.type?.toLowerCase() ?? '')} `
      : ''

    const header = `Your ${type}Transfer Information:`
    const date = formatDateLong(surveyData.date ?? service.date)

    const flightNumber = `Flight Number: ${surveyData.flight_number}`
    const flightTime = surveyData.type === AirportTransferDataType.Arrival
      ? `Landing Time: ${getStartTime(surveyData.start_time, true)}`
      : `Departure Time: ${getStartTime(surveyData.start_time, true)}`

    const adults = pluralise(surveyData.adults, 'adult', 's', '', true)
    const children = surveyData.children
      ? pluralise(surveyData.children, 'child', 'ren', '', true)
      : null

    const guests = joinBy(
      [
        adults,
        children,
      ],
      ' + ',
    )

    let childAges = null
    if (surveyData.child_ages) {
      childAges = `Child ages: ${surveyData.child_ages}`
    }

    let childSeats = null
    if (surveyData.child_seats) {
      childSeats = `Child seats: ${surveyData.child_seats}`
    }

    const phone = `Phone: ${surveyData.main_guest_phone} ${surveyData.main_guest_name}`

    const extraInfo = {
      question: 'Anything else we should know?',
      answer: surveyData.survey?.anything_else,
    }

    {
      return serviceDetails.replaceAll(
        '$generate_details',
        joinBy(
          [
            header,
            date,
            flightNumber,
            flightTime,
            guests,
            childAges,
            childSeats,
            phone,
            ...(extraInfo.answer ? [' ', extraInfo.question, extraInfo.answer] : []),
          ],
          '\n',
        ),
      )
    }
  }

  return serviceDetails
}

export { getAirportTransferServiceDetails }
