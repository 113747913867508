import { useState } from 'react'
import { FacebookIcon, InstagramIcon, PinterestIcon } from './icons'
import Accordion from './accordion'
import AccordionItem from './accordion/AccordionItem'
import PartnerInquiryModal from './PartnerInquiryModal'
import { useQuery } from 'urql'
import { ReactChild } from 'data/types/types'
import { graphql } from 'gql'
import classNames from 'classnames'
import Link from 'components/Link'

const PublicFooterColumnItem = ({
  href,
  children,
}: {
  href: string
  children: ReactChild
}): JSX.Element => {
  return (
    <Link className="text-grey-1000" href={href}>
      {children}
    </Link>
  )
}

const PublicFooterColumn = ({
  title,
  children,
}: {
  title: string
  children: ReactChild
  className?: string
}): JSX.Element => {
  return (
    <div className="flex flex-col flex-grow border-t-grey-1000 border-opacity-20 md:border-opacity-0 md:pt-0">
      <p className="hidden text-16 leading-23 md:mb-20 md:block">{title}</p>
      <div className="md:hidden">
        <Accordion>
          <AccordionItem style={{ container: 'items-center py-12' }} title={title}>
            <div className="flex flex-col gap-5 pl-20 opacity-50 text-grey-900 leading-23">
              {children}
            </div>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="flex-col hidden gap-10 ml-40 opacity-50 text-grey-900 leading-23 md:flex md:m-0">
        {children}
      </div>
    </div>
  )
}

const PublicFooter = ({ className }: { className?: string }) => {
  const [{ data, fetching }] = useQuery({
    query: graphql(`
      query PublicFooterData {
        featuredRegions(first: 6) {
          data {
            id
            slug
            value
            country {
              id
              slug
            }
          }
        }
      }
    `),
  })

  const [partnerModal, setPartnerModal] = useState(false)

  return (
    <div className={classNames(
      'border-t-[1px] border-opacity-20 border-grey-1000 bg-grey-100 md:bg-[#DCDCDC] md:border-opacity-0 pt-30 lg:pt-90 pb-40',
      className,
    )}>
      <div className="container-public">
        <div className="flex flex-col lg:flex-row lg:gap-[130px]">
          <div className="flex flex-col">
            <div>
              <Link className="inline-block pb-4 font-serif font-bold border-b-2 whitespace-nowrap text-24 tracking-1/2 leading-23 mb-30 md:mb-50" href="/">
                A.M.A Selections
              </Link>
            </div>

            <div className="flex-col hidden gap-20 text-24 md:flex mb-50 lg:mb-0">
              <a href="https://instagram.com/ama_selections" className="flex items-center gap-20 text-16 leading-23" target="_blank" rel="noreferrer">
                <InstagramIcon className="text-grey-800 text-20" />
                <p className="text-grey-800">Instagram</p>
              </a>
              <a href="https://facebook.com/amaselections" className="flex items-center gap-20 text-16 leading-23" target="_blank" rel="noreferrer">
                <FacebookIcon className="text-grey-800 text-20" />
                <p className="text-grey-800">Facebook</p>
              </a>
              <a href="https://pinterest.com/amaselections" className="flex items-center gap-20 text-16 leading-23" target="_blank" rel="noreferrer">
                <PinterestIcon className="text-grey-800 text-20" />
                <p className="text-grey-800">Pinterest</p>
              </a>
            </div>
          </div>

          <div className="flex flex-col w-full">
            <div className="flex flex-col  md:flex-row md:flex-wrap gap-0 md:gap-[75px]">
              {
                (data?.featuredRegions?.data == undefined || data.featuredRegions.data.length == 0 || fetching)
                  ? null
                  : <PublicFooterColumn title="Popular Searches">
                    {data.featuredRegions.data.map((dest) => (
                      <PublicFooterColumnItem href={`/${dest.country.slug}/${dest.slug}`} key={dest.id}>{dest.value} Villa Rentals</PublicFooterColumnItem>
                    ))}
                  </PublicFooterColumn>
              }
              <PublicFooterColumn title="Discover More">
                <PublicFooterColumnItem href="/services">Services</PublicFooterColumnItem>
                <PublicFooterColumnItem href="/about-us">Why Book With Us</PublicFooterColumnItem>
                <PublicFooterColumnItem href="/owners">List With Us</PublicFooterColumnItem>
                <PublicFooterColumnItem href="/travel-partners">Become a Travel Partner</PublicFooterColumnItem>

                <PartnerInquiryModal isOpen={partnerModal} onClose={() => setPartnerModal(false)} />
              </PublicFooterColumn>

              <PublicFooterColumn title="About">
                <PublicFooterColumnItem href="/about-us">Our Philosophy</PublicFooterColumnItem>
                <PublicFooterColumnItem href="/about-us">Our Story</PublicFooterColumnItem>
                <PublicFooterColumnItem href="/contact-us">Contact Us</PublicFooterColumnItem>
                <PublicFooterColumnItem href="/membership">Membership & Rewards</PublicFooterColumnItem>
              </PublicFooterColumn>

              <PublicFooterColumn title="Magazine">
                <PublicFooterColumnItem href="/magazine/category?categories=3">Homes</PublicFooterColumnItem>
                <PublicFooterColumnItem href="/magazine/category?categories=4">Food & Drink</PublicFooterColumnItem>
                <PublicFooterColumnItem href="/magazine/category?categories=8">Lifestyle</PublicFooterColumnItem>
              </PublicFooterColumn>
            </div>

            <div className="flex justify-center gap-20 border-t pt-45 text-24 md:hidden border-grey-750 border-opacity-20">
              <a href="https://facebook.com/amaselections" target="_blank" rel="noreferrer">
                <FacebookIcon className="text-grey-800" />
                <div className="sr-only">Facebook</div>
              </a>
              <a href="https://instagram.com/ama_selections" target="_blank" rel="noreferrer">
                <InstagramIcon className="text-grey-800" />
                <div className="sr-only">Instagram</div>
              </a>
              <a href="https://pinterest.com/amaselections" target="_blank" rel="noreferrer">
                <PinterestIcon className="text-grey-800" />
                <div className="sr-only">Pinterest</div>
              </a>
            </div>

            <div className="flex justify-center text-grey-750 text-12 mt-30 md:mt-35 md:justify-between">
              <div className="flex gap-30">
                <Link className="leading-23" href="/privacy-policy">
                  Privacy Policy
                </Link>
                <span className="text-grey-900">|</span>
                <Link className="leading-23" href="/legal-notice">
                  Legal Notice
                </Link>
              </div>
              <p className="hidden text-center text-12 md:block leading-23">© {(new Date()).getFullYear()} A.M.A Selections. All rights reserved</p>
            </div>
            <p className="mt-3 text-center text-grey-800 text-12 md:text-grey-750 md:hidden leading-23">© {(new Date()).getFullYear()} A.M.A Selections. All rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicFooter
