import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  enabled: process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true',
  dsn: SENTRY_DSN,
  tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
})
