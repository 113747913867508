import { AxiosInstance } from 'axios'
import { RestPropertyRate } from 'data/types/rate'

export default class RatesApiClient {
  http: AxiosInstance

  constructor (http: AxiosInstance) {
    this.http = http
  }

  /**
   * Get a list of property rates from the API
   */
  async listRates (
    propertyId: string | number,
    params: {
      page: number,
      per_page?: number,
      start_date?: string,
      end_date?: string,
    },
  ): Promise<{ data: RestPropertyRate[], meta: { current_page: number, last_page: number, total: number } }> {
    const result = await this.http.get(`/rates/${propertyId}`, {
      params,
    })

    return result.data
  }

  /**
   * Create a new property rate
   */
  async createRate (
    propertyId: string | number,
    data: PropertyRateInput,
  ): Promise<RestPropertyRate[]> {
    const result = await this.http.post(`/rates?property_id=${propertyId}`, data)
    return result.data.data
  }

  /**
   * update a property rate
   */
  async updateRate (
    id: string | number,
    propertyId: string | number,
    data: PropertyRateInput,
  ): Promise<RestPropertyRate[]> {
    const result = await this.http.patch(`/rates/${id}?property_id=${propertyId}`, data)
    return result.data.data
  }

  /**
   * delete a property rate
   */
  async deleteRate (
    id: string | number,
    propertyId: string | number,
  ): Promise<RestPropertyRate[]> {
    const result = await this.http.delete(`/rates/${id}?property_id=${propertyId}`)
    return result.data.data
  }
}

export type PropertyRateInput = {
  id?: string | null
  property_id?: string | null
  start_date?: string | null
  end_date?: string | null
  nightly_rate?: number
  minimum_stay?: number
  cleaning_rate?: number
  other_fee?: number
  additional_fee?: number
  nightly_rate_note?: string | null
  other_fee_note?: string | null
  cleaning_rate_note?: string | null
  additional_fee_note?: string | null
}
